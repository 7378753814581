import React from "react";
import ContactForm from "../components/ContactForm";
import {Seo} from '../components/Seo';
import {useContactInfo} from '../utils/getContactInfo';

const ContactPage = () => {
  return (
    <main>
      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-4 lg:grid lg:grid-cols-2">
          <div className="text-black">
            <h1 className="text-5xl md:text-6xl font-black mb-8">The time<br /> is now.</h1>
            <p className="text-sourci-pink text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">1300 SOURCI</p>
            <p className="text-3xl mb-2">hello@sourci.com.au</p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Contact Page" />
          </div>
        </div>
      </section>
      <section className="bg-white py-24">
        <div className="xl:container mx-auto px-4 grid lg:grid-cols-3 gap-x-12 gap-y-8 lg:gap-y-16">
          <div className="lg:col-span-3 text-center lg:text-left">
            <p className="text-4xl md:text-5xl font-black">Where you will <span className="text-sourci-teal">find us.</span></p>
          </div>
          <div className="rounded-3xl border-4 border-solid border-sourci-pink py-12 px-8 text-center">
            <p className="text-3xl font-black text-sourci-pink mb-2">Head Office</p>
            <p className="text-xl text-black font-black mb-4">{useContactInfo().getHeadquarters()} </p>
            <p className="text-xl text-black mb-4">1300 SOURCI</p>
            <p className="text-xl text-black mb-4">hello@sourci.com.au</p>
            <p className="text-xl text-black">{useContactInfo().getAddress(1)}, <br />{useContactInfo().getAddress(2)} <br />{useContactInfo().getAddress(3)}</p>
          </div>
          <div className="rounded-3xl border-4 border-solid border-sourci-teal py-12 px-8 text-center">
            <p className="text-3xl font-black text-sourci-teal mb-2">Shenyang Office</p>
            <p className="text-xl text-black font-black mb-4">China</p>
            <p className="text-xl text-black mb-4">hello@sourci.com.au</p>
            <p className="text-xl text-black">Room 614-615, Building 4, <br />Hongxing Guoji, No.33<br />Xiobeier Road, Tiexi District<br />Shenyang, China</p>
          </div>
          <div className="rounded-3xl border-4 border-solid border-sourci-orange py-12 px-8 text-center">
            <p className="text-3xl font-black text-sourci-orange mb-2">Surat Office</p>
            <p className="text-xl text-black font-black mb-4">India</p>
            <p className="text-xl text-black mb-4">hello@sourci.com.au</p>
            <p className="text-xl text-black">118, Green Plaza <br />Near D-Mart, Mota Varachha,<br />Surat, Gujarat,<br />India, 394101</p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default ContactPage;

export function Head() {
  return (
    <Seo title="Contact Us | Sourci"
      canonical="/contact" />
  )
}